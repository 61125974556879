import React from 'react'
import CssBaseline from '@material-ui/core/CssBaseline'
import { ThemeProvider } from '@material-ui/styles'
import { ToastProvider } from '@covid/ui/core/Toast'
import { AuthProvider } from './auth'
import { UserProvider } from './user'
// import { UserRiskProvider } from './userRisk'
import theme from '../theme'

function AppProviders({ children }) {
  // const initialState = {
  //   patientCovid: 'No identificado',
  //   patientRisk: 'B',
  // }
  // const reducer = (state, action) => {
  //   return {
  //     ...state,
  //     patientCovid: action.patientCovid,
  //     patientRisk: action.patientRisk,
  //   }
  // }

  return (
    <ThemeProvider theme={theme}>
      {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
      <CssBaseline />
      <ToastProvider>
        <AuthProvider>
          <UserProvider>
            {/* <UserRiskProvider initialState={initialState} reducer={reducer}> */}
            {children}
            {/* </UserRiskProvider> */}
          </UserProvider>
        </AuthProvider>
      </ToastProvider>
    </ThemeProvider>
  )
}

export default AppProviders
