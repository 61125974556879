import { getUser } from './auth-client'

async function bootstrapAppData() {
  const { data } = await getUser()

  if (!data) {
    return null
  }

  return data
}

// eslint-disable-next-line import/prefer-default-export
export { bootstrapAppData }
