import client from './api-client'

const localStorageKey = '__clinical_token__'

function handleUserResponse(response) {
  const { data: user } = response
  window.localStorage.setItem(localStorageKey, user.token)
  return user
}

function getUser() {
  const token = getToken()
  if (!token) {
    return Promise.resolve(null)
  }
  return client('auth/patients/me').catch(error => {
    logout()
    return Promise.reject(error)
  })
}

function login({ identification, identificationType, pin }) {
  return client('auth/patients/login', {
    body: { identification, identificationType, pin },
  })
    .then(handleUserResponse)
    .catch(err => Promise.reject(err))
}

function me() {
  return client('auth/patients/me').catch(err => Promise.reject(err))
}

function register({ username, password }) {
  return client('register', { body: { username, password } }).then(
    handleUserResponse
  )
}

function logout() {
  window.localStorage.removeItem(localStorageKey)
  return Promise.resolve()
}

function getToken() {
  return window.localStorage.getItem(localStorageKey)
}

function loginMethod({ identification, identificationType, pin }) {
  return client('auth/patients/login', {
    body: { identification, identificationType, pin },
  }).catch(err => Promise.reject(err))
}

export { login, me, register, logout, getToken, getUser, loginMethod }
